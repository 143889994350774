import React, { useEffect, useMemo, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import Loading from '../_library/Loading'
import MapCard from './MapCard'
import UserCard from './UserCard'
import { format } from 'date-fns'
import ActionBar from './ActionBar'
import FlightParamsCard from './FlightParamsCard'
import { AIRCRAFT_COLLECTION, ITEM_COLLECTION, TANK_COLLECTION, TASK_COLLECTION, TEST_COLLECTION } from '../../_constants/globals'
import TaskCard from './TaskCard'
import { useFirestore } from '../../hooks/useFirestore'
import HILTable from '../aircrafts/HILTable'
import { selectAircraftIdAction } from '../../store/actions/dataActions'
import ItemTable from '../technicalItems/ItemTable'
import ComponentChangeTable from '../technicalItems/ComponentChangeTable'
import AircraftCard from './AircraftCard'
import PropTypes from 'prop-types'


const TestDetails = ({ test }) => {
  
  const dispatch = useDispatch()
  const testHooks = useFirestore(TEST_COLLECTION)
  const itemHooks = useFirestore(ITEM_COLLECTION)
  const dbItems = itemHooks.getDocs()
  const taskHooks = useFirestore(TASK_COLLECTION, { storeAs: 'test-' + test.id })
  const tasks = taskHooks.getDocs()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const tankHooks = useFirestore(TANK_COLLECTION)
  
  const [selectedTab, setSelectedTab] = useState(0)
  
  const { id, label, aircraftRef, departure, title, startDate } = test
  
  useEffect(() => {
    if (aircraftRef) {
      dispatch(selectAircraftIdAction(aircraftRef.id))
      itemHooks.listen({
        where: [['aircraftRef', '==', aircraftHooks.getDocRef(aircraftRef.id)]],
      })
      tankHooks.listen({
        where: [['aircraftRef', '==', aircraftHooks.getDocRef(aircraftRef.id)]],
        orderBy: [['order', 'asc']],
      })
      return () => {
        itemHooks.unsubscribe()
        tankHooks.unsubscribe()
      }
    }
  }, [aircraftRef?.id])
  
  useEffect(() => {
    taskHooks.listen({
      where: [['testRef', '==', testHooks.getDocRef(test.id)]],
      orderBy: [['order', 'asc']],
    })
    return () => taskHooks.unsubscribe()
  }, [test.id])
  
  const mechanicTasks = useMemo(() => dbItems?.filter(i => (!i.testRef || i.testRef.id === test.id) && !i.actionAuthorRef), [dbItems])
  const captainTasks = useMemo(() => dbItems?.filter(i => (!i.testRef || i.testRef.id === test.id) && !i.captainCheck), [dbItems])
  const readyToFly = useMemo(
    () => mechanicTasks.length === 0 && captainTasks.length === 0 && tasks?.every(t => !!t.completedTime || t.moment === 'after') && !test?.offBlocksTime,
    [mechanicTasks, captainTasks, tasks, test?.offBlocksTime],
  )
  
  if (!id || !aircraftRef) return <Loading />
  else return (
    <Box>
      <Box id='test-details-header' sx={{ pt: 1, backgroundImage: 'linear-gradient(to top, #FFFFFF, rgba(255,255,255.9), rgba(255,255,255,.7), rgba(255,255,255,.4), rgba(255,255,255,0));' }}>
        <Box sx={{ px: 2, maxWidth: 1000, mx: 'auto' }}>
          <Typography color='secondary' textTransform='uppercase' mt={1}>{format(startDate, 'PPPP')}</Typography>
          <Typography variant='h1' my={0} fontSize='2rem'>{title}</Typography>
          <Typography variant='caption'>{label}</Typography>
          <ActionBar
            test={test}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            mechanicTasks={mechanicTasks}
            captainTasks={captainTasks}
            readyToFly={readyToFly}
          />
        </Box>
      </Box>
      {selectedTab === 0 && <Grid
        id='overviewPanel' container spacing={2} pr={2} justifyContent='center' display='flex'
        sx={{ pt: 2, pb: '120px', maxWidth: { md: 1000, xl: 'none' }, mx: 'auto', width: '100%' }}
      >
        <Grid item xs={12} sm={6} xl={3}>
          {tasks && <TaskCard test={test} tasks={tasks} />}
        </Grid>
        <Grid item xs={12} sm={6} xl={3}>
          <UserCard />
          <Box sx={{ my: 2 }} />
          <FlightParamsCard withCER={tasks?.some(t => t.slug === 'cer')} />
        </Grid>
      </Grid>}
      {selectedTab === 1 && <Box sx={{ p: 2 }}>
        <ItemTable testId={id} />
        <Box sx={{ my: 2 }} />
        <ComponentChangeTable testId={id} disableAdd={!dbItems?.length} />
      </Box>}
      {selectedTab === 2 && <Grid
        id='briefingPanel' container spacing={2} pr={2} justifyContent='center' display='flex'
        sx={{ pt: 2, pb: '120px', maxWidth: { md: 1000, xl: 'none' }, mx: 'auto', width: '100%' }}
      >
        <Grid item xs={12} lg={3}>
          {tasks && <TaskCard test={test} tasks={tasks} />}
          <Box sx={{ my: 2 }} />
          <UserCard />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ItemTable testId={id} showOpenItems />
          <Box sx={{ my: 2 }} />
          <ComponentChangeTable testId={id} disableAdd={!dbItems?.length} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <AircraftCard aircraftId={test.aircraftRef.id} />
          <Box sx={{ my: 2 }} />
          <HILTable title='HIL items' />
          <Box sx={{ my: 2 }} />
          {departure && <MapCard airport={departure} />}
        </Grid>
      </Grid>}
    </Box>
  )
}

TestDetails.propTypes = {
  test: PropTypes.object.isRequired,
}

export default TestDetails
