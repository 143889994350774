import React from 'react'
import App from './App'
import reportWebVitals from './reportWebVitals'
import packageFile from '../package.json'
import theme from './theme'
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import frLocale from 'date-fns/locale/fr'
import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './store/rootReducer'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import './firebase'
import './index.css'
import { loadReduxState } from './_helpers/localStorageHelper'

window.version = packageFile.version

// FIX CHROME ISSUE WITH navigator.onLine
Object.defineProperty(window.navigator, 'onLine', {
  get: () => true,
})

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadReduxState(),
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }),
})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
          <CssBaseline />
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
